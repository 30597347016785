<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label> <router-link :to="{ name: NewspaperPath.name }" class="text-capitalize  text-gray-900 font-semibold">
          Newspapers</router-link> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i>
        <span class="text-gray-500 font-normal capitalize"> Edit</span>
      </label>
      <h4 class="text-xl capitalize">Edit Newspaper</h4>
    </div>
    <form class="bg-white w-full rounded-lg mt-3 mb-3 px-4 py-4" @submit="onSubmit" id="form-new">
      <div class="mt-3 flex justify-between">
        <div class="w-full flex justify-between">
          <div class="w-1/2 pr-10">
            <div class="block mt-3">
              <label class="font-semibold text-gray-600">Display Name (Item’s Title)</label>
              <input id="display" v-model="form.display_name" class="w-full bg-gray-50 rounded-lg border" type="text"
                placeholder="Enter Display Name" required disabled />
            </div>
            <div>
            </div>
            <div class="grid gap-4 grid-cols-2 mt-3">
              <div>
                <div class="mt-0">
                  <label class="font-semibold text-gray-600 mb-3">Item Status</label>
                  <t-select id="display" v-model="form.item_status" :options="itemStatusOptions" class=" bg-gray-50 rounded-lg "
                   placeholder="Enter Item Status" required >
                  </t-select>
                </div>
              </div>
              <div>
                <div class="">
                  <label class="font-semibold text-gray-600 mb-3">Edition Code</label>
                  <input id="display" v-model="form.edition_code" class="w-full bg-gray-50  rounded-lg border "
                    type="text" placeholder="Enter Item Status" required />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h4 class="text-xl font-bold mb-3 block border-t pt-3 mt-4">Upload Asset</h4>
      <div class="flex justify-start items-center w-1/2 pr-10">
          <div>
        <input class="border w-full rounded-lg" type="file"  ref="fileInput" @change="handleFileChange" placeholder="No file chosen" />
        <span class="text-gray-500 text-sm mt-2 pb-0 block">Max Size 150MB, File Size: {{(fileSizeFormat(this.fileSizeUpload ?? 0))}}, Current File Size: {{fileSizeFormat(this.form.current_size ?? 0)}}</span>
     
          </div>
      <div class="">
        <button
              pill
              size="sm"
              variant="primary"
              @click="onUpload"
              :disabled="isLoading"
              class="bg-black text-white p-2.5 rounded-lg ml-2 mt-0"
              v-if="form.file_content"
            >
            <i v-if="!isLoading" class="fa fa-upload"></i>
              <span v-if="isLoading">Uploading...</span>
              <span v-else>Upload</span>
          </button>
      </div>
      </div>
      <h4 class="text-xl font-bold capitalize block border-t w-full mt-3 pt-3">Detail</h4>
      <div class="justify-start flex">
        <div class="w-1/2 pr-10">
          <div class="grid gap-4 grid-cols-2 mt-3 mb-3">
            <div class="mt-0">
                <label class="font-semibold text-gray-600 mb-3">Printed Price Currency (Optional)</label>
                <t-select id="currency" v-model="form.currency" :options="currencys" required></t-select>
              </div>
              <div class="mt-0">
                <label class="font-semibold mb-3 text-gray-600">Printed Price (Optional)</label>
                <input id="price" v-model="form.price" type="number" class="w-full bg-gray-50 rounded-lg border"
                  step="any" placeholder="99000 (no sparator)" required />
              </div>
          </div>
          <DatetimeCustom :label="'Release Date (GMT ' + timezoneString() + ')'" v-model="form.release_date">
          </DatetimeCustom>
          <DatetimeCustom class="mt-3" :label="'Release Schedule (GMT ' + timezoneString() + ')'"
            v-model="form.release_schedule"></DatetimeCustom>

          <div class="flex justify-start mt-4">
            <button class="bg-blue-700 p-2 px-2 text-white rounded-lg " type="submit" form="form-new"
              :hidden="isLoading">
              Save
            </button>
            <button
              class="bg-white border-blue-700  text-blue-700 border-l border-t border-b border-r rounded-lg ml-3 px-3"
              type="reset">Reset Form</button>
            <button class="bg-white p-2 px-3 text-gray-800 border rounded-lg ml-3 ">
              <router-link :to="{ name: NewspaperPath.name }" class="text-capitalize  text-gray-800 font-normal">
                Cancel</router-link>
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-overlay>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { NewspaperPath } from "../../../router/content";
import constant from "../../../store/constant";
import DatetimeCustom from "../../../components/form/Datetime.vue";
import {EventBus} from '../../../event-bus'
const ITEM_STATUS = Object.entries(constant.ITEM_STATUS).map(item => { return { text: item[0], value: item[1] } })
const PRINTED_PRICE = Object.values(constant.PRINTED_PRICE_ITEM);

export default {
  name: "editNewspaper",
  components: {
    DatetimeCustom,
  },
  data() {
    return {
      NewspaperPath,
      form: {
        brand: null,
        file_content: null,
        display_name: null,
        price:"",
        currency: 'IDR',
        item_status: null,
        release_date: null,
        release_schedule: null
      },
      currencys: [
      { text: "Select Currency", value: null }, ...PRINTED_PRICE],
      itemStatusOptions: ITEM_STATUS,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.newspaper.isError,
      isLoading: (state) => state.newspaper.isLoading,
      errorMessage: (state) => state.newspaper.errorMessage,
      successMessage: (state) => state.newspaper.successMessage,
      item: (state) => state.newspaper.item,
      refresh: (state) => state.newspaper.refresh,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
      //this.messageAlert('error', this.errorMessage, 5000);
      EventBus.$emit('show-toast', {
            message:this.errorMessage,
            type: 'success',
          });
    },
    successMessage: function () {
      if (!this.successMessage) return;
      EventBus.$emit('show-toast', {
                    message: this.successMessage,
                    type: 'success',
                });
    },
    item: function () {
      if (!Object.keys(this.item).length) return;
      this.setEditNewspaper();
    },
    refresh: function () {
      if (!this.refresh) return;
      this.fetchNewsPaperById({ id: this.item_id });
      this.$router.push(NewspaperPath);
    },
  },
  created() {
    let id = this.$route.params.id;
    this.item_id = id;
    this.fetchNewsPaperById({ id });
  },
  methods: {
    ...mapActions('newspaper', [
      'updateNewspaper',
      'uploadAsset',
      'fetchNewsPaperById',
    ]),
    onSubmit(event) {
      event.preventDefault();
      const payload = {
        item_id: this.item_id,
        release_schedule: this.form.release_schedule,
        release_date: this.form.release_date,
        item_status: this.form.item_status,
        edition_code: this.form.edition_code,
        printed_price: this.form.price,
        printed_currency_code: this.form.currency
      };
         this.updateNewspaper(payload)
    },

    onUpload() {
      this.isDownload = true;
      const payload = {
        id: this.item_id,
        file_content: this.form.file_content,
        item_status: this.form.item_status,
        release_schedule: this.form.release_schedule,
      };
      if (!payload.file_content) {
        this.messageAlert('error', 'No File Selected !', 5000);
        return;
      }
       this.uploadAsset(payload);
       this.successOnsubmit = false;
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
    handleFileChange(event) {
      const file = event.target.files[0];
       this.form.file_content = file;
       this.fileSizeUpload = file.size
    },
    fileSizeFormat(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return (
        parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      );
    },
    setEditNewspaper() {
      const data = this.item;
      this.form.display_name = data.name;
      this.form.release_schedule = moment(data.release_schedule).toString();
      this.form.release_date = moment(data.release_date).toString();
      this.form.current_size = data.file_size;
      this.form.file_content = null;
      this.form.item_status = data.item_status;
      this.form.edition_code = data.edition_code;
      this.form.price = data.printed_price
      this.form.currency = data.printed_currency_code
    },
  },
  filters: {
    convertSize(value) {
      const size = parseFloat(value);
      return (size / 1000 ** 2).toFixed(1)
    }
  }
};
</script>

<style lang="css" scoped></style>
